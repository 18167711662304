import { Http } from "../../../data/protocols/services/api";
import { HttpRegistrationRedirect } from "../../../services/account-user/redirect";
import { StorageHelper } from "../../../utils/functions/storage/storage";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setCurrentCampaign } from "store/slices/campaign";

enum BannerLinkType {
  NoneLink = "NoneLink",
  RedirectToCatalog = "RedirectToCatalog",
  CustomLink = "CustomLink",
}

interface ButtonsProps {
  campaignDetails: {
    bannerCustomLink: string;
    bannerLinkType: BannerLinkType | 0;
    customUrl: string | null;
    descriptiveCampaign: string;
    endDate: Date;
    id: string;
    imgCampaign: string;
    isExpired: boolean;
    name: string;
    optinProgram: boolean
    optinProgramStatus: boolean
    programId: string;
    startDate: Date;
    visibleForAll: boolean;
  };
  campaignOptin: {
    optinProgram: boolean | null;
    optinStatus: boolean | null;
  }
  user: {
    document_number: string | null;
    email: string | null;
  };
}

export const Buttons = (props: ButtonsProps) => {
  const { campaignDetails, campaignOptin, user } = props;
  const dispatch = useDispatch();

  const http = new Http();
  const storageHelper = new StorageHelper();
  const httpRegistrationRedirect = new HttpRegistrationRedirect(
    http,
    storageHelper
  );

  const handleRedirectToCatalog = async () => {
    try {
      const log = await httpRegistrationRedirect.getUrlRedirect({
        data: {
          documentNumber: user.document_number as string,
          email: user.email as string,
        },
      });
      localStorage.setItem("user_redirect", "isRedirect");
      window.location.href = log.response.data;
    } catch (error: unknown) {
      toast.error("Não foi possível redirecionar para o catálogo.", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "error_redirect_catalog",
      });
    }
  };

  const handleRedirectToCustomLink = () => {
    window.open(campaignDetails.bannerCustomLink, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {campaignOptin.optinProgram && campaignDetails.bannerLinkType === BannerLinkType["RedirectToCatalog"] && (
        <button
          className="button-rescue-now mb-1"
          onClick={handleRedirectToCatalog}
        >
          Resgate Agora
        </button>
      )}
      {campaignOptin.optinProgram && campaignDetails.bannerLinkType === BannerLinkType["CustomLink"] && (
      <button
      className="button-rescue-now mb-1"
      onClick={handleRedirectToCustomLink}
    >
      Acumule Pontos
    </button>
      )}
      <button
        className="button-how-work"
        onClick={() => {
          if (!campaignDetails?.customUrl) {
            dispatch(
              setCurrentCampaign({
                id: campaignDetails.id,
                optinProgram: campaignOptin.optinProgram,
                optinStatus: campaignOptin.optinStatus,
              })
            );
            window.location.href = `/programa/${campaignDetails.programId}/${campaignDetails.id}`;
          }
        }}
      >
        Como funciona
      </button>
    </>
  );
};
