import { Http } from "../../../data/protocols/services/api";
import { HttpRegistrationRedirect } from "../../../services/account-user/redirect";
import { StorageHelper } from "../../../utils/functions/storage/storage";
import { toast } from "react-toastify";

interface ButtonsProps {
  campaignDetails: {
    bannerCustomLink: string;
    bannerLinkType: "NoneLink" | "RedirectToCatalog" | "CustomLink";
    name: string;
  };
  campaignOptin: {
    optinProgram: boolean | null;
    optinStatus: boolean | null;
  };
  isLogged: boolean;
  modal: boolean;
  setModal: (modal: boolean) => void;
  user: {
    document_number: string | null
    email: string | null
  }
}

export const Buttons = (props: ButtonsProps) => {
  const { campaignDetails, campaignOptin, modal, setModal, isLogged, user } = props;

  const http = new Http();
  const storageHelper = new StorageHelper();
  const httpRegistrationRedirect = new HttpRegistrationRedirect(
    http,
    storageHelper
  );

  const handleRedirectToCatalog = async () => {
      try {
        const log = await httpRegistrationRedirect.getUrlRedirect({
          data: {
            documentNumber: user.document_number as string,
            email: user.email as string,
          },
        });
        localStorage.setItem("user_redirect", "isRedirect");
        window.location.href = log.response.data;
      } catch (error: unknown) {
            toast.error('Não foi possível redirecionar para o catálogo.', {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "error_redirect_catalog",
            });
      }
  };

  const handleRedirectToCustomLink = () => {
    window.open(campaignDetails.bannerCustomLink, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="box-sign " id={isLogged ? "isLogged" : "notLogged"}>
      <h3 className="text-[20px]">
        <strong>{campaignDetails.name}</strong>
      </h3>

      <div id={isLogged ? "isLogged" : "notLogged"}>
        {campaignOptin.optinProgram && campaignOptin.optinStatus ? (
          <>
            <p className="bold underline mt-2">
              VOCÊ JÁ ESTÁ CADASTRADO NESTE PROGRAMA.
            </p>
            {campaignDetails.bannerLinkType === "RedirectToCatalog" && (
              <button className="button-rescue-now mb-1" onClick={handleRedirectToCatalog}>
                Resgate Agora
              </button>
            )}
            {campaignDetails.bannerLinkType === "CustomLink" && (
              <button
                className="button-rescue-now mt-1 mb-1"
                onClick={handleRedirectToCustomLink}
              >
                Acumule Pontos
              </button>
            )}
          </>
        ) : campaignOptin.optinProgram && !campaignOptin.optinStatus ? (
          <>
            <p className="bold underline mt-2">
              VOCÊ ESTÁ INSCRITO NO PROGRAMA, PORÉM PRECISA ACEITAR O
              REGULAMENTO.
            </p>
            <div
              className="button-rescue-now mt-1 mb-1"
              onClick={() => setModal(!modal)}
            >
              ACEITAR O REGULAMENTO
            </div>
          </>
        ) : (
          <>
            <button
              className="button-rescue-now mt-1 mb-1"
              onClick={() => setModal(!modal)}
            >
              PARTICIPAR DO PROGRAMA
            </button>
          </>
        )}
      </div>
    </div>
  );
};
